import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { ButtonSecondary } from '../../../components/atoms/Button/ButtonSecondary'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="IRライブラリ"
      headingEnglish={ENGLISH_TITLE.IRLibrary}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: 'IRライブラリ', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <div className="pc:flex flex-wrap">
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pr-3">
            <ButtonSecondary label="決算短信" url="/ir/library/results/" />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pl-3">
            <ButtonSecondary
              label="決算説明資料"
              url="/ir/library/presentations/"
            />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pr-3">
            <ButtonSecondary
              label="有価証券報告書等"
              url="/ir/library/securities/"
            />
          </div>
          <div className="mb-4 pc:mb-6 pc:w-6/12 pc:pl-3">
            <ButtonSecondary label="株主通信" url="/ir/library/reports/" />
          </div>
          <div className="mb-4 pc:mb-0 pc:w-6/12 pc:pr-3">
            <ButtonSecondary
              label="適時開示情報"
              url="/ir/library/disclosure/"
            />
          </div>
          <div className="mb-4 pc:mb-0 pc:w-6/12 pc:pl-3">
            <ButtonSecondary label="その他IR資料" url="/ir/library/others/" />
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => {
  return (
    <>
      <title>
        IRライブラリ | IR情報 | 総合人材サービスのCRGホールディングス
      </title>
      <meta
        name="keywords"
        content="IRライブラリ,IR,人材派遣,総合アウトソーシング,CRGホールディングス"
      />
      <meta
        name="description"
        content="株主・投資家の皆様へ。CRGホールディングスの「IRライブラリ」について。決算短信、決算説明資料、有価証券報告書等、株主通信、時開示情報、その他IR資料をご覧いただけます。"
      />
      <meta
        property="og:title"
        content="IRライブラリ | IR情報 | 総合人材サービスのCRGホールディングス"
      />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://www.crgh.co.jp/ir/library/" />
      <meta
        property="og:image"
        content="https://www.crgh.co.jp/common/img/og_image.png"
      />
      <meta property="og:site_name" content="CRGホールディングス" />
      <meta
        property="og:description"
        content="株主・投資家の皆様へ。CRGホールディングスの「IRライブラリ」について。決算短信、決算説明資料、有価証券報告書等、株主通信、時開示情報、その他IR資料をご覧いただけます。"
      />
      {/* 20230926 追加 */}
      <meta
        name="facebook-domain-verification"
        content="ty5782t7m4xyz5t30mnss9u10t9lgh"
      />
    </>
  )
}
